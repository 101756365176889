body {
  margin: 40;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page-element {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}

.product-list {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 1rem;
  & :hover {
    cursor: pointer;
  }
}

.cart-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.social-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
  & :hover {
    cursor: pointer;
    color: #ee9b00;
    transition: all 0.3s;
  }
}

.user-profile {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  margin: 2rem
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-filters {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 2rem 0;
  gap: 1rem;
}

.heading {
  margin: 2rem 2rem 2rem 2rem;
}

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}